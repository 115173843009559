export default [{
  path: '/sistemas',
  component: {
    name: 'sistemasBase',
    template: '<router-view />',
    resource: 'sistema',
    meta: {
      resource: 'sistema',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'visualizar',
      name: 'sistemas',
      component: () => import('@/app/sistema/SistemaView.vue'),
      meta: {
        resource: 'sistema',
        action: 'read',
        navActiveLink: 'sistemas',
        pageTitle: 'Cadastro de Sistemas',
        breadcrumb: [
          { text: 'Sistemas', to: { name: 'sistemas' }, active: true },
        ],
      },
    },
    {
      path: 'novo',
      name: 'novo-sistema',
      component: () => import('@/app/sistema/shared/components/cadastro/Sistema.vue'),
      meta: {
        resource: 'sistema',
        action: 'create',
        navActiveLink: 'sistemas',
        pageTitle: 'Cadastro de Sistemas',
        breadcrumb: [
          {
            text: 'Sistemas',
            to: { name: 'sistemas' },
          },
          {
            text: 'Novo',
            to: { name: 'sistema' },
            active: true,
          },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'editar-sistema',
      component: () => import('@/app/sistema/shared/components/cadastro/Sistema.vue'),
      meta: {
        resource: 'sistema',
        action: 'update',
        navActiveLink: 'sistemas',
        pageTitle: 'Cadastro de Sistemas',
        breadcrumb: [
          { text: 'Sistemas', to: { name: 'sistemas' } },
          { text: 'Editar', to: { name: 'editar-sistema' }, active: true },
        ],
      },
    },
  ],
}]
