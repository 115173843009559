import jwtDefaultConfig from '@/auth/jwt/jwtDefaultConfig'
import Vue from 'vue'
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
// export const isUserLoggedIn = () => {
//   return localStorage.getItem('userData') && localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
// }

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return { name: 'access-control' }
  return { name: 'auth-login' }
}

export const isGoingToLogging = (from, to) => {
  const fromDiffPaths = from.name !== to.name
  return fromDiffPaths && to.path.startsWith('/login')
}

export const isGoingToHome = (from, to) => {
  const fromDiffPaths = from.name !== to.name
  return fromDiffPaths && (to.path === '/')
}

// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  const logged = localStorage.getItem('userData') && localStorage.getItem(jwtDefaultConfig.storageTokenKeyName)
  return logged
}

export const isAdmin = () => {
  const userData = getUserData()
  return userData && userData.admin
}

export const clearLoggedIn = () => {
  localStorage.removeItem('userData')
  localStorage.removeItem(jwtDefaultConfig.storageTokenKeyName)
}

export const updateAbility = ability => {
  const userData = getUserData()
  const existingAbility = userData ? userData.ability : null
  if (ability) {
    ability.update(existingAbility || [])
  }
}

export const permitidoAcessarRecurso = subject => {
  const user = getUserData()
  if (user.admin) {
    return true
  }
  const abilitys = user.ability
  const ability = abilitys.find(find => find.subject === subject)
  return ability
}

export const permitidoExecutarAcao = (acao, recursoAcessado) => {
  if (isAdmin()) return true
  const ability = permitidoAcessarRecurso(recursoAcessado)
  if (ability.actions.includes(acao)) return true
  Vue.swal({
    text: 'Você não tem permissão para realizar está ação',
    title: 'Oops...',
    icon: 'error',
    confirmButtonColor: 'red',
  })
  return false
}
