import Vue from 'vue'
// axios
import axios from 'axios'
import { API_URL } from '../../urlConfig'

const axiosIns = axios.create({
  // You can add your headers here
  baseURL: API_URL,
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
