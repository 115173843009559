export default [
  {
    path: '/',
    name: '/',
    meta: {
      requiredAuth: true,
      action: 'eden',
    },
  },
]
