export default [{
  path: '/hosts',
  component: {
    name: 'hostsBase',
    template: '<router-view />',
    meta: {
      resource: 'servidorhost',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'visualizar',
      name: 'hosts',
      component: () => import('@/app/host/HostView.vue'),
      meta: {
        resource: 'servidorhost',
        action: 'read',
        navActiveLink: 'hosts',
        pageTitle: 'Cadastro de Hosts',
        breadcrumb: [
          { text: 'Hosts', to: { name: 'hosts' }, active: true },
        ],
      },
    },
    {
      path: 'novo',
      name: 'novo-host',
      component: () => import('@/app/host/shared/components/cadastro/Host.vue'),
      meta: {
        resource: 'servidorhost',
        action: 'create',
        navActiveLink: 'hosts',
        pageTitle: 'Cadastro de Hosts',
        breadcrumb: [
          {
            text: 'Hosts',
            to: { name: 'hosts' },
          },
          {
            text: 'Novo',
            to: { name: 'host' },
            active: true,
          },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'editar-host',
      component: () => import('@/app/host/shared/components/cadastro/Host.vue'),
      meta: {
        resource: 'servidorhost',
        action: 'update',
        navActiveLink: 'hosts',
        pageTitle: 'Cadastro de Hosts',
        breadcrumb: [
          { text: 'Hosts', to: { name: 'hosts' } },
          { text: 'Editar', to: { name: 'editar-host' }, active: true },
        ],
      },
    },
  ],
}]
