export default [{
  path: '/agenndas',
  component: {
    name: 'agendasBase',
    template: '<router-view />',
    meta: {
      requiredAuth: false,
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'agenda-geral/visualizar',
      name: 'agenda-geral',
      component: () => import('@/app/agenda/shared/components/Agenda.vue'),
      meta: {
        requiredAuth: false,
        navActiveLink: 'agenda-geral',
        pageTitle: 'Agenda Geral',
        breadcrumb: [
          { text: 'Agenda Geral', to: { name: 'agenda-geral' }, active: true },
        ],
      },
    },
    {
      path: 'agenda-pessoal/visualizar',
      name: 'agenda-pessoal',
      component: () => import('@/app/agenda/shared/components/Agenda.vue'),
      meta: {
        requiredAuth: false,
        navActiveLink: 'agenda-pessoal',
        pageTitle: 'Agenda Pessoal',
        breadcrumb: [
          { text: 'Agenda Pessoal', to: { name: 'agenda-pessoal' }, active: true },
        ],
      },
    },
  ],
}]
