import Vue from 'vue'
import { getUserData } from '@/auth/utils'
import api from '@/app/quadro-implantacao/shared/services/api'

const state = {
  filtro: null,
  idQuadro: null,
  quadro: null,
  quadrosGerais: null,
  quadrosPessoais: null,
  quadrosParticipantes: null,
}

// eslint-disable-next-line no-shadow
// const get = key => state => state[key]

const getters = {
  filtro: state_ => state_.filtro,
  id: state_ => state_.idQuadro,
  quadro: state_ => state_.quadro,
  quadrosGerais: state_ => state_.quadrosGerais,
  quadrosPessoais: state_ => state_.quadrosPessoais,
  quadrosParticipantes: state_ => state_.quadrosParticipantes,
}

const actions = {
  getQuadro({ commit }, { id, filtro }) {
    api.getQuadroById(id, filtro).then(payload => {
      const quadro = payload.data
      quadro.colunas = quadro.colunas && quadro.colunas.length > 0 ? quadro.colunas : []
      commit('SET_QUADRO', payload.data)
    })
  },

  getQuadrosGerais({ commit }, status) {
    api.getQuadrosGerais(status)
      .then(payload => {
        commit('SET_QUADROS_GERAIS', payload.data)
      })
  },
  getQuadrosPessoais({ commit }, { userId, status }) {
    api.getQuadrosPessoais(userId, status)
      .then(payload => {
        commit('SET_QUADROS_PESSOAIS', payload.data)

        commit('SET_PARTICIPANTES', { quadros: state.quadrosPessoais, tipoQuadro: 'quadrosPessoais' })
      })
  },
  getQuadrosParticipantes({ commit }, { userId, status }) {
    api.getQuadrosParticipantes(userId, status)
      .then(payload => {
        commit('SET_QUADROS_PARTICIPANTES', payload.data)

        commit('SET_PARTICIPANTES', { quadros: state.quadrosParticipantes, tipoQuadro: 'quadrosParticipantes' })
      })
  },
  setFiltros({ commit }, filtro) {
    commit('SET_FILTRO', filtro)
  },
  salvar({ commit }, quadro) {
    commit('CRIAR_QUADRO', quadro)
  },
  editar({ commit }, quadro) {
    commit('EDITAR_QUADRO', quadro)
  },
}

const mutations = {
  SET_FILTRO(state_, filtro) {
    Vue.set(state_, 'filtro', filtro)
  },

  SET_QUADRO(state_, quadro) {
    quadro.colunas.push({
      titulo: 'Adicionar uma lista', adicionarNova: true, criandoNovaLista: false,
    })
    Vue.set(state_, 'quadro', quadro)
  },

  SET_QUADROS_GERAIS(state_, quadros) {
    Vue.set(state_, 'quadrosGerais', quadros)
  },

  SET_QUADROS_PESSOAIS(state_, quadros) {
    Vue.set(state_, 'quadrosPessoais', quadros)
  },

  SET_QUADROS_PARTICIPANTES(state_, quadros) {
    Vue.set(state_, 'quadrosParticipantes', quadros)
  },

  SET_PARTICIPANTES(state_, { quadros, tipoQuadro }) {
    quadros.forEach(quadro => {
      // eslint-disable-next-line
        quadro.participantes = quadro.participantes.map(({ id, participanteId, nome }) => ({ id: participanteId, participanteId: id, nome }))
    })
    Vue.set(state_, tipoQuadro, quadros)
  },

  CRIAR_QUADRO(state_, quadro) {
    const newQuadro = quadro
    newQuadro.participantes = quadro.participantes.map(({ id, nome }) => ({ participanteId: id, nome }))
    newQuadro.autorId = getUserData().id
    api.adicionarQuadro(newQuadro).then(payload => {
      const quadroRotornado = payload.data
      if (quadroRotornado.tipo === 'Geral') {
        state_.quadrosGerais.push(quadroRotornado)
      } else {
        state_.quadrosPessoais.push(quadroRotornado)
      }
      Vue.set(state_, 'quadroId', quadroRotornado.id)
    })
  },
  EDITAR_QUADRO(state_, quadro) {
    const newQuadro = quadro
    newQuadro.participantes = quadro.participantes.map(({ id, participanteId, nome }) => ({ id: participanteId, participanteId: id, nome }))
    api.atualizarQuadro(newQuadro).then(payload => {
      const quadroRotornado = payload.data
      if (quadroRotornado.tipo === 'Geral') {
        const index = state_.quadrosGerais.findIndex(item => item.id === quadroRotornado.id)
        Vue.set(state_.quadrosGerais, index, quadroRotornado)
      } else {
        const index = state_.quadrosPessoais.findIndex(item => item.id === quadroRotornado.id)
        Vue.set(state_.quadrosPessoais, index, quadroRotornado)
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
