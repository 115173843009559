export default [{
  path: '/chamados',
  component: {
    name: 'chamadosBase',
    template: '<router-view />',
    meta: {
      resource: 'chamado',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'visualizar',
      name: 'chamados',
      component: () => import('@/app/chamado/ChamadoView.vue'),
      meta: {
        resource: 'chamado',
        action: 'read',
        navActiveLink: 'chamados',
        pageTitle: 'Chamados',
        breadcrumb: [
          { text: 'Chamados', to: { name: 'chamados' }, active: true },
        ],
      },
    },
    {
      path: 'relatorio-vendas',
      name: 'vendas-suporte',
      component: () => import('@/app/chamado/shared/components/relatorio-vendas/RelatorioVendas.vue'),
      meta: {
        resource: 'vendasuporte',
        action: 'read',
        navActiveLink: 'vendasuporte',
        pageTitle: 'Vendas do Suporte',
        breadcrumb: [
          { text: 'Relatório de Vendas do Suporte', to: { name: 'vendas-suporte' }, active: true },
        ],
      },
    },
    {
      path: 'novo',
      name: 'novo-chamado',
      component: () => import('@/app/chamado/shared/components/cadastro/Chamado.vue'),
      meta: {
        resource: 'chamado',
        action: 'create',
        navActiveLink: 'chamados',
        pageTitle: 'Chamados',
        breadcrumb: [
          {
            text: 'Chamados',
            to: { name: 'chamados' },
          },
          {
            text: 'Novo',
            to: { name: 'novo-chamado' },
            active: true,
          },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'editar-chamado',
      component: () => import('@/app/chamado/shared/components/cadastro/Chamado.vue'),
      meta: {
        resource: 'chamado',
        action: 'update',
        navActiveLink: 'chamados',
        pageTitle: 'Chamados',
        breadcrumb: [
          { text: 'Chamados', to: { name: 'chamados' } },
          { text: 'Editar', to: { name: 'editar-chamado' }, active: true },
        ],
      },
    },
  ],
}]
