export default [{
  path: '/compromissos/categorias',
  component: {
    name: 'categoriasCompromissosBase',
    template: '<router-view />',
    resource: 'categoriacompromisso',
    meta: {
      resource: 'categoriacompromisso',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'visualizar',
      name: 'categorias-compromissos',
      component: () => import('@/app/agenda/categorias-compromissos/CategoriaCompromissoView.vue'),
      meta: {
        resource: 'categoriacompromisso',
        action: 'read',
        navActiveLink: 'categorias-compromissos',
        pageTitle: 'Categorias de Compromisso',
        breadcrumb: [
          { text: 'Categorias de Compromisso', to: { name: 'categorias-compromissos' }, active: true },
        ],
      },
    },
    {
      path: 'nova',
      name: 'nova-categoria-compromisso',
      component: () => import('@/app/agenda/categorias-compromissos/shared/components/cadastro/CategoriaCompromisso.vue'),
      meta: {
        resource: 'categoriacompromisso',
        action: 'create',
        navActiveLink: 'categorias-compromissos',
        pageTitle: 'Categorias de Compromisso',
        breadcrumb: [
          {
            text: 'Categorias de Compromisso',
            to: { name: 'categorias-compromissos' },
          },
          {
            text: 'Nova',
            to: { name: 'nova-categoria-compromisso' },
            active: true,
          },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'editar-categoria-compromisso',
      component: () => import('@/app/agenda/categorias-compromissos/shared/components/cadastro/CategoriaCompromisso.vue'),
      meta: {
        resource: 'categoriacompromisso',
        action: 'update',
        navActiveLink: 'categorias-compromissos',
        pageTitle: 'Categorias de Compromisso',
        breadcrumb: [
          { text: 'Categorias de Compromisso', to: { name: 'categorias-compromissos' } },
          { text: 'Editar', to: { name: 'editar-categoria-compromisso' }, active: true },
        ],
      },
    },
  ],
}]
