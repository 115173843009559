export default [{
  path: '/rotinas',
  component: {
    name: 'rotinasBase',
    template: '<router-view />',
    meta: {
      resource: 'rotinamanutencao',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'visualizar',
      name: 'rotinas',
      component: () => import('@/app/rotina/RotinaView.vue'),
      meta: {
        resource: 'rotinamanutencao',
        action: 'read',
        navActiveLink: 'rotinas',
        pageTitle: 'Cadastro de Rotinas',
        breadcrumb: [
          { text: 'Rotinas', to: { name: 'rotinas' }, active: true },
        ],
      },
    },
    {
      path: 'novo',
      name: 'nova-rotina',
      component: () => import('@/app/rotina/shared/components/cadastro/Rotina.vue'),
      meta: {
        resource: 'rotinamanutencao',
        action: 'create',
        navActiveLink: 'rotinas',
        pageTitle: 'Cadastro de Rotinas',
        breadcrumb: [
          {
            text: 'Rotinas',
            to: { name: 'rotinas' },
          },
          {
            text: 'Novo',
            to: { name: 'rotina' },
            active: true,
          },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'editar-rotina',
      component: () => import('@/app/rotina/shared/components/cadastro/Rotina.vue'),
      meta: {
        resource: 'rotinamanutencao',
        action: 'update',
        navActiveLink: 'rotinas',
        pageTitle: 'Cadastro de Rotinas',
        breadcrumb: [
          { text: 'Rotinas', to: { name: 'rotinas' } },
          { text: 'Editar', to: { name: 'editar-rotina' }, active: true },
        ],
      },
    },
    {
      path: 'detalhes/:id',
      name: 'detalhes-rotina',
      component: () => import('@/app/rotina/shared/components/detalhes/RotinaDetails.vue'),
      meta: {
        resource: 'rotinamanutencao',
        action: 'read',
        navActiveLink: 'rotinas',
        pageTitle: 'Detalhes da rotina',
        breadcrumb: [
          { text: 'Rotinas', to: { name: 'rotinas' } },
          { text: 'Detalhes', to: { name: 'detalhes-rotina' }, active: true },
        ],
      },
    },
  ],
}]
