export default [{
  path: '/grupos',
  component: {
    resource: 'grupo',
    action: 'read',
    name: 'gruposBase',
    template: '<router-view />',
    meta: {
      resource: 'grupo',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    name: 'grupos',
    component: () => import('@/app/grupo/GrupoView.vue'),
    meta: {
      resource: 'grupo',
      action: 'read',
      navActiveLink: 'grupos',
      pageTitle: 'Cadastro de Grupos',
      breadcrumb: [
        {
          text: 'Grupos de usuários',
          to: { name: 'grupos' },
          active: true,
        },
      ],
    },
  },
  {
    path: 'novo',
    name: 'novo-grupo',
    component: () => import('@/app/grupo/shared/components/cadastro/Grupo.vue'),
    meta: {
      resource: 'grupo',
      action: 'create',
      navActiveLink: 'grupos',
      pageTitle: 'Cadastro de Grupos',
      breadcrumb: [
        {
          text: 'Grupos de usuários',
          to: { name: 'grupos' },
        },
        {
          text: 'Novo',
          to: { name: 'novo-grupo' },
          active: true,
        },
      ],
    },
  },
  {
    path: 'editar/:id',
    name: 'editar-grupo',
    component: () => import('@/app/grupo/shared/components/cadastro/Grupo.vue'),
    meta: {
      resource: 'grupo',
      action: 'update',
      navActiveLink: 'grupos',
      pageTitle: 'Cadastro de Grupos',
      breadcrumb: [
        {
          text: 'Grupos de usuários',
          to: { name: 'grupos' },
        },
        {
          text: 'Editar',
          to: { name: 'editar-grupo' },
          active: true,
        },
      ],
    },
  }],
}]
