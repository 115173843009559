export default [{
  path: '/produtos',
  component: {
    name: 'ProdutoBase',
    template: '<router-view />',
    meta: {
      resource: 'produto',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'visualizar',
      name: 'produtos',
      component: () => import('@/app/produto/ProdutoView.vue'),
      meta: {
        resource: 'produto',
        action: 'read',
        navActiveLink: 'produtos',
        pageTitle: 'Cadastro de Produtos',
        breadcrumb: [
          { text: 'Produtos', to: { name: 'produtos' }, active: true },
        ],
      },
    },
    {
      path: 'novo',
      name: 'novo-produto',
      component: () => import('@/app/produto/shared/components/cadastro/Produto.vue'),
      meta: {
        resource: 'produto',
        action: 'create',
        navActiveLink: 'produtos',
        pageTitle: 'Cadastro de Produtos',
        breadcrumb: [
          {
            text: 'Produtos',
            to: { name: 'produtos' },
          },
          {
            text: 'Novo',
            to: { name: 'produtos' },
            active: true,
          },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'editar-produto',
      component: () => import('@/app/produto/shared/components/cadastro/Produto.vue'),
      meta: {
        resource: 'produto',
        action: 'update',
        navActiveLink: 'produtos',
        pageTitle: 'Cadastro de Produtos',
        breadcrumb: [
          { text: 'Produtos', to: { name: 'produtos' } },
          { text: 'Editar', to: { name: 'editar-produto' }, active: true },
        ],
      },
    },
  ],
}]
