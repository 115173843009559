export default [{
  path: '/clientes',
  component: {
    name: 'clientesBase',
    template: '<router-view />',
    meta: {
      resource: 'cliente',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'visualizar',
      name: 'clientes',
      component: () => import('@/app/cliente/ClienteView.vue'),
      meta: {
        resource: 'cliente',
        action: 'read',
        navActiveLink: 'clientes',
        pageTitle: 'Cadastro de Clientes',
        breadcrumb: [
          { text: 'Clientes', to: { name: 'clientes' }, active: true },
        ],
      },
    },
    {
      path: 'novo',
      name: 'novo-cliente',
      component: () => import('@/app/cliente/shared/components/cadastro/Cliente.vue'),
      meta: {
        resource: 'cliente',
        action: 'create',
        navActiveLink: 'clientes',
        pageTitle: 'Cadastro de Clientes',
        breadcrumb: [
          {
            text: 'Clientes',
            to: { name: 'clientes' },
          },
          {
            text: 'Novo',
            to: { name: 'clientes' },
            active: true,
          },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'editar-cliente',
      component: () => import('@/app/cliente/shared/components/cadastro/Cliente.vue'),
      meta: {
        resource: 'cliente',
        action: 'update',
        navActiveLink: 'clientes',
        pageTitle: 'Cadastro de Clientes',
        breadcrumb: [
          { text: 'Clientes', to: { name: 'clientes' } },
          { text: 'Editar', to: { name: 'editar-cliente' }, active: true },
        ],
      },
    },
  ],
}]
