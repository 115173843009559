export default [{
  path: '/categoria-produto',
  component: {
    name: 'categoriaProdutoBase',
    template: '<router-view />',
    meta: {
      resource: 'categoriaproduto',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'visualizar',
      name: 'categoria-produto',
      component: () => import('@/app/categoria-produto/CategoriaProdutoView.vue'),
      meta: {
        resource: 'categoriaproduto',
        action: 'read',
        navActiveLink: 'categoria-produto',
        pageTitle: 'Cadastro de Categoria de Produto',
        breadcrumb: [
          { text: 'Categoria de Produto', to: { name: 'categoriaProduto' }, active: true },
        ],
      },
    },
    {
      path: 'novo',
      name: 'novo-categoria-produto',
      component: () => import('@/app/categoria-produto/shared/components/CategoriaProduto.vue'),
      meta: {
        resource: 'categoriaproduto',
        action: 'create',
        navActiveLink: 'categoria-produto',
        pageTitle: 'Cadastro de Categoria de Produto',
        breadcrumb: [
          {
            text: 'Categoria de produto',
            to: { name: 'categoria-produto' },
          },
          {
            text: 'Novo',
            to: { name: 'categoria-produto' },
            active: true,
          },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'editar-categoria-produto',
      component: () => import('@/app/categoria-produto/shared/components/CategoriaProduto.vue'),
      meta: {
        resource: 'categoriaproduto',
        action: 'update',
        navActiveLink: 'categoria-produto',
        pageTitle: 'Cadastro de Categoria de Produto',
        breadcrumb: [
          { text: 'Categoria de produto', to: { name: 'categoria-produto' } },
          { text: 'Editar', to: { name: 'editar-categoria-produto' }, active: true },
        ],
      },
    },
  ],
}]
