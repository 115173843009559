/* eslint-disable no-param-reassign */

export default
{
  customClassConfirmacao: tipo => ({
    confirmButton: 'btn  btn-outline-primary mr-50',
    cancelButton: `btn ${tipo === 'danger' ? 'btn-outline-danger' : 'btn-outline-warning'}`,
  }),

  install(Vue) {
    const confirmar = (titulo, mensagem, tipo) => {
      if (tipo === 'success' || tipo === 'warning') {
        return Vue.swal({
          title: titulo,
          text: mensagem,
          icon: tipo,
          showCancelButton: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: `btn w-50 btn-outline-${tipo}`,
          },
          buttonsStyling: false,
        })
      }
      return Vue.swal({
        title: titulo,
        text: mensagem,
        icon: tipo,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: this.customClassConfirmacao(tipo),
        buttonsStyling: false,
      })
    }
    Vue.prototype.$confirmar = confirmar

    const question = (titulo, mensagem, tipo) => {
      if (tipo === 'question') {
        return Vue.swal({
          title: titulo,
          text: mensagem,
          icon: tipo,
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger',
          },
          buttonsStyling: true,
        })
      }
      return Vue.swal({
        title: titulo,
        text: mensagem,
        icon: tipo,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: this.customClassConfirmacao(tipo),
        buttonsStyling: false,
      })
    }
    Vue.prototype.$question = question
  },
}
