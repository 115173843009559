export default {
  // Endpoints
  loginEndpoint: '/seguranca/login',
  changePasswordEndpoint: '/seguranca/change-password',
  forgotPasswordEndpoint: '/seguranca/forgot-password',
  registerEndpoint: '',
  refreshEndpoint: '',
  logoutEndpoint: '',

  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: '',
}
