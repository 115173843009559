export default [{
  path: '/tipos-atendimentos',
  component: {
    name: 'tipoAtendimentoBase',
    template: '<router-view />',
    resource: 'tipoatendimento',
    meta: {
      resource: 'tipoatendimento',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'visualizar',
      name: 'tipos-atendimentos',
      component: () => import('@/app/tipo-atendimento/TipoAtendimentoView.vue'),
      meta: {
        resource: 'tipoatendimento',
        action: 'read',
        navActiveLink: 'tipos-atendimentos',
        pageTitle: 'Tipos de Atendimentos',
        breadcrumb: [
          { text: 'Tipos de Atendimentos', to: { name: 'tipos-atendimentos' }, active: true },
        ],
      },
    },
    {
      path: 'novo',
      name: 'novo-tipo-atendimento',
      component: () => import('@/app/tipo-atendimento/shared/components/cadastro/TipoAtendimento.vue'),
      meta: {
        resource: 'tipoatendimento',
        action: 'create',
        navActiveLink: 'tipos-atendimentos',
        pageTitle: 'Cadastro de Tipo de Atendimento',
        breadcrumb: [
          {
            text: 'Tipos de Atendimentos',
            to: { name: 'tipos-atendimentos' },
          },
          {
            text: 'Novo',
            to: { name: 'categoria' },
            active: true,
          },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'editar-tipo-atendimento',
      component: () => import('@/app/tipo-atendimento/shared/components/cadastro/TipoAtendimento.vue'),
      meta: {
        resource: 'tipoatendimento',
        action: 'update',
        navActiveLink: 'tipos-atendimentos',
        pageTitle: 'Editar Tipo de Atendimento',
        breadcrumb: [
          { text: 'Tipos de Atendimentos', to: { name: 'tipos-atendimentos' } },
          { text: 'Editar', to: { name: 'editar-tipo-atendimento' }, active: true },
        ],
      },
    },
  ],
}]
