<template>
  <b-row>
    <b-col>

      <b-row
        v-for="item in valueAttr"
        :key="item.id"
      >
        <b-col>
          <b-row
            align-v="center"
            class="menu-permissao-linha"
          >
            <b-col
              :style="recuoPadding"
              cols="7"
            >
              <b-row align-v="center">
                <b-col
                  cols="auto"
                  class="py-50 pr-0"
                >
                  <b-button
                    v-if="item.filhos && item.filhos.length > 0"
                    v-b-toggle="`linha-${item.id}`"
                    style="background-color: inherit !important;"
                    pill
                    :aria-expanded="'true'"
                    variant="none"
                    class="btn-sm border-0 rounded p-50 text-secondary"
                  >
                    <feather-icon :icon="'ChevronDownIcon'" />
                  </b-button>
                  <span
                    v-else
                    class="recuo-sem-filhos"
                  />
                </b-col>
                <b-col cols="8">
                  <b-checkbox
                    v-if="!item.filhos || item.filhos.length == 0"
                    v-model="item.habilitado"
                    :disabled="admin"
                    @change="sendToParent()"
                  >
                    {{ item.descricao }}
                  </b-checkbox>
                  <span v-else>
                    <b>{{ item.descricao }}</b>
                  </span>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <permissoes
                v-if="!item.filhos || item.filhos.length == 0"
                v-model="item.permissao"
                :disabled="!item.habilitado"
                :admin="admin"
                :portal="item.habilitado && item.regraRecurso === 'somentealteracao'"
                :sem-remocao="item.habilitado && item.regraRecurso === 'semremocao'"
                @input="sendToParent()"
              />
            </b-col>
          </b-row>
          <b-collapse
            v-if="item.filhos"
            :id="`linha-${item.id}`"
            visible
          >
            <b-row>
              <b-col>
                <menu-permissao-item
                  v-model="item.filhos"
                  :posicao="posicao + 1"
                  :admin="admin"
                  @input="sendToParent()"
                />
              </b-col>
            </b-row>
          </b-collapse>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'MenuPermissaoItem',
  components: {
    Permissoes: () => import('./Permissao.vue'),
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    posicao: {
      type: Number,
      required: true,
    },
    admin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueAttr: [],
      disabledPermissao: true,
    }
  },
  computed: {
    recuoPadding: {
      get() {
        return `padding-left:${this.posicao * 32}px;`
      },
    },
  },
  created() {
    this.valueAttr = this.value
  },
  methods: {
    sendToParent() {
      this.$emit('input', !this.valueAttr ? null : this.valueAttr)
    },
  },
}
</script>
