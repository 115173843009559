export default [{
  path: '/log-geral',
  component: {
    name: 'logBase',
    template: '<router-view />',
    meta: {
      resource: 'logger',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'visualizar',
      name: 'log-geral',
      component: () => import('@/app/log-geral/LogGeralView.vue'),
      meta: {
        resource: 'logger',
        action: 'read',
        navActiveLink: 'log-geral',
        pageTitle: 'Relatório de Logs',
        breadcrumb: [
          { text: 'Logs', to: { name: 'logs' }, active: true },
        ],
      },
    },
  ],
}]
