/* eslint-disable */
import Vue from 'vue'
import BootstrapVue, { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import VueCompositionAPI from '@vue/composition-api'
import MaskedInput from 'vue-text-mask'
import VueMask from 'v-mask'
import VueClipboard from 'vue-clipboard2'
import VMoney from 'v-money'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faRectangleList,
  faComment,
  faFaceSmile,
  faClock,
} from '@fortawesome/free-solid-svg-icons'
import Notificacao from './@pilar/plugins/notificacao'
import Confirmacao from './@pilar/plugins/confirmacao'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'

// Add Fonts in FontAwesome Library
library.add(faRectangleList, faComment, faFaceSmile, faClock)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(VueMask)
Vue.use(Notificacao)
Vue.use(Confirmacao)
Vue.use(VMoney, { precision: 4 })
Vue.use(VueApexCharts)
Vue.use(VueClipboard)
Vue.use(VueQuillEditor)

Vue.component('masked-input', MaskedInput)
Vue.component('apexchart', VueApexCharts)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
