import axios from '@axios'

const PREFIX = '/tarefas'

export default {

  // Quadros
  getQuadroById(quadroId, filtro) {
    return axios.post(`${PREFIX}/quadros/${quadroId}`, filtro)
  },

  getQuadrosGerais(status) {
    return axios.get(`${PREFIX}/quadros/gerais/${status}`)
  },

  getQuadrosPessoais(userId, status) {
    return axios.get(`${PREFIX}/quadros/pessoais/usuario/${userId}/status?status=${status}`)
  },

  getQuadrosParticipantes(userId, status) {
    return axios.get(`${PREFIX}/quadros/participantes/usuario/${userId}/status?status=${status}`)
  },

  getAllQuadros(status) {
    return axios.get(`${PREFIX}/quadros/todos/status?status=${status}`)
  },

  adicionarQuadro(quadro) {
    return axios.post(`${PREFIX}/quadros/novo`, quadro)
  },

  atualizarColunasQuadro(quadro) {
    return axios.put(`${PREFIX}/quadros/${quadro.id}/colunas`, quadro)
  },

  atualizarQuadro(quadro) {
    return axios.put(`${PREFIX}/quadros/${quadro.id}`, quadro)
  },

  deletarQuadro(id) {
    return axios.delete(`${PREFIX}/quadros/${id}`)
  },

  // Colunas
  getColunasPadroes() {
    return axios.get(`${PREFIX}/colunas/padroes`)
  },

  getColunasByQuadroId(id) {
    return axios.get(`${PREFIX}/quadro/${id}/colunas`)
  },

  adicionarColuna(coluna) {
    return axios.post(`${PREFIX}/colunas/nova`, coluna)
  },

  atualizarColuna(coluna) {
    return axios.put(`${PREFIX}/coluna/${coluna.id}`, coluna)
  },

  editarTituloColuna(id, titulo) {
    return axios.put(`${PREFIX}/${id}/coluna/titulo?titulo=${titulo}`)
  },

  deletarColuna(id) {
    return axios.delete(`${PREFIX}/coluna/${id}`)
  },

  // Cartões
  adicionarCartao(cartao) {
    return axios.post(`${PREFIX}/cartoes/novo`, cartao)
  },

  arquivarCartao(quadroId, cartao) {
    return axios.put(`${PREFIX}/cartoes/${quadroId}/arquivar`, cartao)
  },

  deletarCartao(id) {
    return axios.delete(`${PREFIX}/cartoes/${id}`)
  },

  getCartaoById(id) {
    return axios.get(`${PREFIX}/cartoes/${id}`)
  },

  getCartaoCapaById(id) {
    return axios.get(`${PREFIX}/cartoes/${id}/capa`)
  },

  getCatoesByColuna(colunaId, page, pageSize, filtro) {
    return axios.post(`${PREFIX}/colunas/${colunaId}?page=${page}&pageSize=${pageSize}`, filtro)
  },

  alterarColunaCartao(cartao) {
    return axios.put(`${PREFIX}/cartoes/${cartao.id}/coluna`, cartao)
  },

  async alterarPosicaoCartao(cartao) {
    return axios.put(`${PREFIX}/cartoes/${cartao.id}/posicao`, cartao)
  },

  async alterarTitulo(id, titulo) {
    return axios.put(`${PREFIX}/cartoes/${id}/titulo?titulo=${titulo}`)
  },

  atualizarMembrosCartao(cartao) {
    return axios.put(`${PREFIX}/cartoes/${cartao.id}/membros`, cartao)
  },

  atualizarEtiquetasCartao(cartao) {
    return axios.put(`${PREFIX}/cartoes/${cartao.id}/etiquetas`, cartao)
  },

  atualizarDescricaoCartao(cartao) {
    return axios.put(`${PREFIX}/cartoes/${cartao.id}/descricao`, cartao)
  },

  atualizarDataEntregaCartao(cartao) {
    return axios.put(`${PREFIX}/cartoes/${cartao.id}/data-entrega`, cartao)
  },

  // Etiquetas
  getEtiquetas() {
    return axios.get(`${PREFIX}/etiquetas`)
  },

  adicionarEtiqueta(etiqueta) {
    return axios.post(`${PREFIX}/etiquetas/nova`, etiqueta)
  },

  // Anexos
  atualizarAnexos(cartaoId, formData) {
    return axios.put(`${PREFIX}/cartoes/${cartaoId}/anexos`, formData)
  },

  getAnexos(id) {
    return axios.get(`${PREFIX}/cartoes/${id}/anexos`)
  },

  deletarAnexo(cartaoId, autor, quadroId, tituloCartao, nomeAnexo) {
    return axios.delete(`${PREFIX}/cartoes/${cartaoId}/anexos?autor=${autor}&quadroId=${quadroId}&tituloCartao=${tituloCartao}&nomeAnexo=${nomeAnexo}`)
  },

  // Atividades
  atualizarAtividades(cartao) {
    return axios.put(`${PREFIX}/cartoes/${cartao.id}/atividades`, cartao)
  },

  checkAtividade(atividadeId, concluida) {
    return axios.put(`${PREFIX}/cartoes/${atividadeId}/check?concluida=${concluida}`)
  },

  atualizarGrupoAtividades(cartao) {
    return axios.post(`${PREFIX}/cartoes/${cartao.id}/grupo-atividades`, cartao)
  },

  deletarGrupoAtividade(grupoAtividadeId) {
    return axios.delete(`${PREFIX}/grupos-atividades/${grupoAtividadeId}`)
  },

  deletarAtividade(grupoId, atividadeId) {
    return axios.delete(`${PREFIX}/grupos/${grupoId}/atividades/${atividadeId}`)
  },
  getHistoricoCartao(cartaoId) {
    return axios.get(`${PREFIX}/historicos/${cartaoId}`)
  },
  getUltimoHistoricoCartao(cartaoId) {
    return axios.get(`${PREFIX}/ultimo-historico/${cartaoId}`)
  },
  duplicarCartao(cartao) {
    return axios.post(`${PREFIX}/cartoes/duplicar`, cartao)
  },
}
