export default [{
  path: '/links',
  component: {
    resource: 'redelink',
    action: 'read',
    name: 'linksBase',
    template: '<router-view />',
    meta: {
      resource: 'redelink',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [{
    path: 'visualizar',
    name: 'links',
    component: () => import('@/app/rede-link/RedeLinkView.vue'),
    meta: {
      resource: 'redelink',
      action: 'read',
      navActiveLink: 'links',
      pageTitle: 'Cadastro de Provedores',
      breadcrumb: [
        {
          text: 'Provedores',
          to: { name: 'links' },
          active: true,
        },
      ],
    },
  },
  {
    path: 'novo',
    name: 'novo-link',
    component: () => import('@/app/rede-link/shared/components/cadastro/RedeLink.vue'),
    meta: {
      resource: 'redelink',
      action: 'create',
      navActiveLink: 'links',
      pageTitle: 'Cadastro de Provedores',
      breadcrumb: [
        {
          text: 'Provedores',
          to: { name: 'links' },
        },
        {
          text: 'Novo',
          to: { name: 'novo-link' },
          active: true,
        },
      ],
    },
  },
  {
    path: 'editar/:id',
    name: 'editar-link',
    component: () => import('@/app/rede-link/shared/components/cadastro/RedeLink.vue'),
    meta: {
      resource: 'redelink',
      action: 'update',
      navActiveLink: 'links',
      pageTitle: 'Cadastro de Provedores',
      breadcrumb: [
        {
          text: 'Provedores',
          to: { name: 'links' },
        },
        {
          text: 'Editar',
          to: { name: 'editar-link' },
          active: true,
        },
      ],
    },
  }],
}]
