export default [{
  path: '/natureza-operacao',
  component: {
    name: 'naturezaOperacaoBase',
    template: '<router-view />',
    meta: {
      resource: 'naturezaoperacao',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'visualizar',
      name: 'natureza-operacao',
      component: () => import('@/app/natureza-operacao/NaturezaOperacaoView.vue'),
      meta: {
        resource: 'naturezaoperacao',
        action: 'read',
        navActiveLink: 'natureza-operacao',
        pageTitle: 'Cadastro de Natureza de Operação',
        breadcrumb: [
          { text: 'Natureza de Operação', to: { name: 'natureza-operacao' }, active: true },
        ],
      },
    },
    {
      path: 'novo',
      name: 'novo-natureza-operacao',
      component: () => import('@/app/natureza-operacao/shared/components/NaturezaOperacao.vue'),
      meta: {
        resource: 'naturezaoperacao',
        action: 'create',
        navActiveLink: 'natureza-operacao',
        pageTitle: 'Cadastro de Natureza de Operação',
        breadcrumb: [
          {
            text: 'Natureza de Operação',
            to: { name: 'natureza-operacao' },
          },
          {
            text: 'Novo',
            to: { name: 'natureza-operacao' },
            active: true,
          },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'editar-natureza-operacao',
      component: () => import('@/app/natureza-operacao/shared/components/NaturezaOperacao.vue'),
      meta: {
        resource: 'naturezaoperacao',
        action: 'update',
        navActiveLink: 'natureza-operacao',
        pageTitle: 'Cadastro de Natureza de Operação',
        breadcrumb: [
          {
            text: 'Natureza de Operação',
            to: { name: 'natureza-operacao' },
          },
          {
            text: 'Editar',
            to: { name: 'editar-natureza-operacao' },
            active: true,
          },
        ],
      },
    },
  ],
}]
