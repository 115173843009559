export default [{
  path: '/scripts',
  component: {
    name: 'scriptsBase',
    template: '<router-view />',
    meta: {
      resource: 'script',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'visualizar',
      name: 'scripts',
      component: () => import('@/app/script/ScriptView.vue'),
      meta: {
        resource: 'script',
        action: 'read',
        navActiveLink: 'scripts',
        pageTitle: 'Cadastro de Scripts',
        breadcrumb: [
          { text: 'Scripts', to: { name: 'scripts' }, active: true },
        ],
      },
    },
    {
      path: 'novo',
      name: 'novo-script',
      component: () => import('@/app/script/shared/components/cadastro/Script.vue'),
      meta: {
        resource: 'script',
        action: 'create',
        navActiveLink: 'scripts',
        pageTitle: 'Cadastro de Scripts',
        breadcrumb: [
          {
            text: 'Scripts',
            to: { name: 'scripts' },
          },
          {
            text: 'Novo',
            to: { name: 'script' },
            active: true,
          },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'editar-script',
      component: () => import('@/app/script/shared/components/cadastro/Script.vue'),
      meta: {
        resource: 'script',
        action: 'update',
        navActiveLink: 'scripts',
        pageTitle: 'Cadastro de Scripts',
        breadcrumb: [
          { text: 'Scripts', to: { name: 'scripts' } },
          { text: 'Editar', to: { name: 'editar-script' }, active: true },
        ],
      },
    },
  ],
}]
