export default [{
  path: '/perfil-empresa',
  name: 'perfil-empresa',
  component: () => import('@/app/perfil-empresa/PerfilEmpresa.vue'),
  meta: {
    resource: 'perfilempresa',
    action: 'create',
    pageTitle: 'Perfil Empresa',
    breadcrumb: [],
  },
}]
