export default [{
  path: '/revendas',
  component: {
    name: 'revendasBase',
    template: '<router-view />',
    meta: {
      resource: 'revenda',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'visualizar',
      name: 'revendas',
      component: () => import('@/app/revenda/RevendaView.vue'),
      meta: {
        resource: 'revenda',
        action: 'read',
        navActiveLink: 'revendas',
        pageTitle: 'Cadastro de Revendas',
        breadcrumb: [
          { text: 'Revendas', to: { name: 'revendas' }, active: true },
        ],
      },
    },
    {
      path: 'novo',
      name: 'novo-revenda',
      component: () => import('@/app/revenda/shared/components/cadastro/Revenda.vue'),
      meta: {
        resource: 'revenda',
        action: 'create',
        navActiveLink: 'revendas',
        pageTitle: 'Cadastro de Revendas',
        breadcrumb: [
          {
            text: 'Revendas',
            to: { name: 'revendas' },
          },
          {
            text: 'Novo',
            to: { name: 'revenda' },
            active: true,
          },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'editar-revenda',
      component: () => import('@/app/revenda/shared/components/cadastro/Revenda.vue'),
      meta: {
        resource: 'revenda',
        action: 'update',
        navActiveLink: 'revendas',
        pageTitle: 'Cadastro de Revendas',
        breadcrumb: [
          { text: 'Revendas', to: { name: 'revendas' } },
          { text: 'Editar', to: { name: 'editar-revenda' }, active: true },
        ],
      },
    },
  ],
}]
