export default [
  {
    name: 'auth-login',
    path: '/login',
    component: () => import('@/app/login/Login.vue'),
    meta: {
      resource: 'login',
      action: 'eden',
      requiredAuth: false,
      layout: 'full',
    },
  },
]
