export default [{
  path: '/tipo-servico',
  component: {
    name: 'tipoServicoBase',
    template: '<router-view />',
    meta: {
      resource: 'tiposervico',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'visualizar',
      name: 'tipo-servico',
      component: () => import('@/app/tipo-servico/TipoServicoView.vue'),
      meta: {
        resource: 'tiposervico',
        action: 'read',
        navActiveLink: 'tipo-servico',
        pageTitle: 'Cadastro do Tipo de Serviço',
        breadcrumb: [
          { text: 'Tipo de Serviço', to: { name: 'tipo-servico' }, active: true },
        ],
      },
    },
    {
      path: 'novo',
      name: 'novo-tipo-servico',
      component: () => import('@/app/tipo-servico/shared/components/TipoServico.vue'),
      meta: {
        resource: 'tiposervico',
        action: 'create',
        navActiveLink: 'tipo-servico',
        pageTitle: 'Cadastro do Tipo de Serviço',
        breadcrumb: [
          {
            text: 'Tipo de Serviço',
            to: { name: 'tipo-servico' },
          },
          {
            text: 'Novo',
            to: { name: 'tipo-servico' },
            active: true,
          },
        ],
      },
    },
    {
      path: 'editar/:id',
      name: 'editar-tipo-servico',
      component: () => import('@/app/tipo-servico/shared/components/TipoServico.vue'),
      meta: {
        resource: 'tiposervico',
        action: 'update',
        navActiveLink: 'tipo-servico',
        pageTitle: 'Cadastro do Tipo de Serviço',
        breadcrumb: [
          {
            text: 'Tipo de Serviço',
            to: { name: 'tipo-servico' },
          },
          {
            text: 'Editar',
            to: { name: 'editar-tipo-servico' },
            active: true,
          },
        ],
      },
    },
  ],
}]
