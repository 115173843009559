export default [{
  path: '/etiquetas',
  component: {
    name: 'etiquetasBase',
    template: '<router-view />',
    meta: {
      resource: 'etiqueta',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'visualizar/:quadroId',
      name: 'etiquetas',
      component: () => import('@/app/etiqueta/EtiquetaView.vue'),
      meta: {
        resource: 'etiqueta',
        action: 'read',
        navActiveLink: 'etiquetas',
        pageTitle: 'Cadastro de Etiquetas',
        breadcrumb: [
          { text: 'Etiquetas', to: { name: 'etiquetas' }, active: true },
        ],
      },
    },
    {
      path: 'nova/:quadroId',
      name: 'nova-etiqueta',
      component: () => import('@/app/etiqueta/shared/components/cadastro/Etiqueta.vue'),
      meta: {
        resource: 'etiqueta',
        action: 'create',
        navActiveLink: 'etiquetas',
        pageTitle: 'Cadastro de Etiquetas',
        breadcrumb: [
          {
            text: 'Etiquetas',
            to: { name: 'etiquetas' },
          },
          {
            text: 'Nova',
            to: { name: 'etiquetas' },
            active: true,
          },
        ],
      },
    },
    {
      path: 'editar/:id/:quadroId',
      name: 'editar-etiqueta',
      component: () => import('@/app/etiqueta/shared/components/cadastro/Etiqueta.vue'),
      meta: {
        resource: 'etiqueta',
        action: 'update',
        navActiveLink: 'etiquetas',
        pageTitle: 'Cadastro de Etiquetas',
        breadcrumb: [
          { text: 'Etiquetas', to: { name: 'etiquetas' } },
          { text: 'Editar', to: { name: 'editar-etiqueta' }, active: true },
        ],
      },
    },
  ],
}]
