export default [{
  path: '/notificacoes',
  component: {
    name: 'notificacaoBase',
    template: '<router-view />',
    meta: {
      resource: 'notificacao',
      action: 'read',
      breadcrumb: [
        { text: '', to: { path: '/' } },
      ],
    },
  },
  children: [
    {
      path: 'visualizar',
      name: 'notificacoes',
      component: () => import('@/app/notificacao/shared/components/Notificacao.vue'),
      meta: {
        resource: 'notificacao',
        action: 'read',
        navActiveLink: 'notificacoes',
        pageTitle: 'Visualizar Todas as Notificações',
        breadcrumb: [
          { text: 'Visualizar Notificações', to: { name: 'notificacoes' }, active: true },
        ],
      },
    },
    {
      path: 'pesquisar',
      name: 'pesquisar-notificacao',
      component: () => import('@/app/notificacao/NotificacaoView.vue'),
      meta: {
        resource: 'notificacao',
        action: 'read',
        navActiveLink: 'notificacoes',
        pageTitle: 'Pesquisar por Notificações',
        breadcrumb: [
          {
            text: 'Pesquisar Notificações',
            to: { name: 'notificacoes' },
            active: true,
          },
        ],
      },
    }],
}]
